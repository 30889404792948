import { INITIAL_PROVINCES_SEARCH_PARAMS } from 'src/views/system/provinces/partials/Constants'
import { PROVINCES_DATA, SET_PROVINCES_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  provinces: [],
  searchParams: INITIAL_PROVINCES_SEARCH_PARAMS,
}

export default function ProvincesReducer(state = initialState, action) {
  switch (action.type) {
    case PROVINCES_DATA:
      return { ...state, provinces: action.payload }
    case SET_PROVINCES_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
