export const INITIAL_TRANSACTIONS_SEARCH_PARAMS = {
  id: '',
  sku: '',
  client_id: '',
  location_name: '',
  lot: '',
  expiry_date: '',
  transaction_type_id: '',
  batch_id: '',
  order_number: '',
  internal_order: '',
  warehouse_id: '',
  quantity: '',
  reference: '',
  created_by: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}
