import { INITIAL_SHIPPING_SEARCH_PARAMS } from 'src/views/shipping/partials/Constants'
import { SET_SHIPPING_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  searchParams: INITIAL_SHIPPING_SEARCH_PARAMS,
}
export default function ShippingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHIPPING_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
