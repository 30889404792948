import {
  INITIAL_RETURN_ORDER_SEARCH_PARAMS,
  INITIAL_RETURN_SEARCH_PARAMS,
} from 'src/views/return/partials/components/Constants'
import { RETURN_ORDER_SEARCH_ADD, SET_RETURN_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  fields: [],
  returnOrderSearchParams: INITIAL_RETURN_ORDER_SEARCH_PARAMS,
  returnSearchParams: INITIAL_RETURN_SEARCH_PARAMS,
}
export default function ReturnReducer(state = initialState, action) {
  switch (action.type) {
    case RETURN_ORDER_SEARCH_ADD:
      return { ...state, returnOrderSearchParams: action.payload }
    case SET_RETURN_SEARCH_PARAMS:
      return { ...state, returnSearchParams: action.payload }
    default:
      return state
  }
}
