import { INITIAL_SUPPLIERS_SEARCH_PARAMS } from 'src/views/clients/suppliers/partials/Constants'
import { SET_SUPPLIERS_SEARCH_PARAMS, SUPPLIERS_DATA } from '../actions/actionType'

const initialState = {
  suppliers: [],
  searchParams: INITIAL_SUPPLIERS_SEARCH_PARAMS,
}
export default function SuppliersReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPLIERS_DATA:
      return { ...state, suppliers: action.payload }
    case SET_SUPPLIERS_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
