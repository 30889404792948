export const ROUTE = {
  HOME: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboards/dashboard',
  DASHBOARD_EXTERNAL: '/dashboard',
  RETURN_LIST: '/returns',
  RETURN_CREATE: '/returns/create',
  RETURN_EDIT: '/returns/edit',
  RETURN_VIEW: '/returns/view',
  SUPPLIER_LIST: '/suppliers',
  SUPPLIER_CREATE: '/suppliers/create',
  SUPPLIER_EDIT: '/suppliers/edit',
  SUPPLIER_VIEW: '/suppliers/view',
  COUNTRIES_LIST: '/system/countries',
  COUNTRIES_CREATE: '/system/countries/create',
  COUNTRIES_EDIT: '/system/countries/edit',
  COUNTRIES_VIEW: '/system/countries/view',
  PROVINCES_LIST: '/system/provinces',
  PROVINCES_CREATE: '/system/provinces/create',
  PROVINCES_EDIT: '/system/provinces/edit',
  PROVINCES_VIEW: '/system/provinces/view',
  ORDER_LIST: '/orders',
  ORDER_CREATE: '/orders/create',
  ORDER_EDIT: '/orders/edit',
  ORDER_VIEW: '/orders/view',
  PURCHASE_ORDER_LIST: '/po',
  PURCHASE_ORDER_CREATE: '/po/create',
  PURCHASE_ORDER_EDIT: '/po/edit',
  PURCHASE_ORDER_VIEW: '/po/view',
  PURCHASE_ORDER_IMPORT: '/po/import',
  ITEM_LIST: '/products/items',
  ITEM_EDIT: '/products/items/edit',
  ITEM_VIEW: '/products/items/view',
  ITEM_CREATE: '/products/items/create',
  ITEM_IMPORT: '/products/items/import',
  RECEIVING_LIST: '/receiving',
  RECEIVING_CREATE: '/receiving/create',
  RECEIVING_EDIT: '/receiving/edit',
  RECEIVING_VIEW: '/receiving/view',
  APPOINTMENT_LIST: '/appointments',
  APPOINTMENT_CREATE: '/appointments/create',
  APPOINTMENT_EDIT: '/appointments/edit',
  APPOINTMENT_CALENDAR: '/appointments/calendar',
  APPOINTMENT_IMPORT: '/appointments/import',
  APPOINTMENT_VIEW: '/appointments/view',
  ZONES_LIST: '/zones',
  ZONES_CREATE: '/zones/create',
  ZONES_EDIT: '/zones/edit',
  ZONES_VIEW: '/zones/view',
  SHIPPING_LIST: '/shipping',
  SHIPPING_CREATE: '/shipping/create',
  SHIPPING_EDIT: '/shipping/edit',
  SHIPPING_VIEW: '/shipping/view',
  SHIPPING_TRANSFER_PACKAGE: '/shipping/transfer-package',
}
