import { JOBS_STATUS_DATA, JOBS_TYPE_DATA, SET_JOBS_SEARCH_PARAMS } from 'src/actions/actionType'
import { INITIAL_JOBS_SEARCH_PARAMS } from 'src/views/jobs/partials/Constants'

const initialState = {
  jobsType: [],
  jobsStatus: [],
  searchParams: INITIAL_JOBS_SEARCH_PARAMS,
}

export default function JobsReducer(state = initialState, action) {
  switch (action.type) {
    case JOBS_TYPE_DATA:
      return { ...state, jobsType: action.payload }
    case JOBS_STATUS_DATA:
      return { ...state, jobsStatus: action.payload }
    case SET_JOBS_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
