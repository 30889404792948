import { INITIAL_ITEM_SEARCH_PARAMS } from 'src/views/items/items/partials/components/constants'
import { SET_ITEM_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  items: [],
  searchParams: INITIAL_ITEM_SEARCH_PARAMS,
}
export default function ItemReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ITEM_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
