import { INITIAL_TRANSACTIONS_SEARCH_PARAMS } from 'src/views/inventory/transactions/partials/Constants'
import { SET_TRANSACTIONS_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  searchParams: INITIAL_TRANSACTIONS_SEARCH_PARAMS,
}

export default function TransactionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSACTIONS_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
