import { APPOINTMENT_STATUS } from 'src/constants/Constants'

export const INITIAL_APPOINTMENTS_SEARCH_PARAMS = {
  id: '',
  client_id: '',
  warehouse_id: '',
  scheduled_date: '',
  scheduled_time: '',
  carrier_id: '',
  appointment_status_id: APPOINTMENT_STATUS.OPEN,
  skids: '',
  boxes: '',
  created_by: '',
  asn: '',
  order_by: 'id',
  sort_method: 'desc',
  limit: 15,
  page: 1,
}

export const INITIAL_REFUSED_STATE = {
  appointment_id: '',
  warehouse_door_id: '',
  refused_reason: '',
  image: '',
  comments: '',
}

export const INITIAL_APPOINTMENTS = {
  clients: [],
  purchase_orders: [],
  warehouse_id: '',
  delivery_type_id: '',
  carrier_id: '',
  carrier_name: '',
  department_id: '',
  appointment_status_id: 1,
  scheduled_date: '',
  scheduled_time: '',
  carrier_email: '',
  slots: '',
  skids: 0,
  boxes: 0,
  asn: '',
  notes: '',
}
