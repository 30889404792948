export const INITIAL_PURCHASE_ORDER_SEARCH_PARAMS = {
  id: '',
  purchase_order_status_id: '',
  purchase_order_condition_id: '',
  client_id: '',
  po_number: '',
  internal_po: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_PO = {
  client_id: '',
  supplier_id: '',
  po_number: '',
  warehouse_id: '',
  purchase_order_condition_id: 1,
  purchase_order_status_id: 1,
  shipping_method_id: '',
  date_expected: '',
  date_expected_current: '',
  purchase_order_items: [],
  client_po_comment: '',
  internal_po_comment: '',
}

export const INITIAL_ITEM = {
  subclient_id: '',
  item_id: '',
  in_stock: '',
  quantity_expected: '',
  purchase_order_item_status_id: 1,
  currency_id: '',
  supplier_code: '',
  description: '',
  uom_type_id: 1,
  lot: '',
  upc: '',
  cost: '',
  total: 0,
  item: {},
}
