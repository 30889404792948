import { INITIAL_ZONE_SEARCH_PARAMS } from 'src/views/zones/partials/Constants'
import { SET_ZONE_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  searchParams: INITIAL_ZONE_SEARCH_PARAMS,
}
export default function ZoneReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ZONE_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
