import http from '../utils/http-common'

const getStatuses = () => {
  return http.get('/appointment-statuses')
}

const getConditions = () => {
  return http.get('/appointment-conditions')
}

const getAll = (param, cancelToken) => {
  return http.get('/appointments', { params: param, cancelToken: cancelToken?.token })
}

const get = (id) => {
  return http.get(`/appointments/${id}`)
}

const create = (data) => {
  return http.post(`/appointments`, data)
}

const update = (id, data) => {
  return http.put(`/appointments/${id}`, data)
}

const getTasks = () => {
  return http.get(`/appointment-tasks`)
}
const remove = (id) => {
  return http.delete(`/appointments/${id}`)
}

const refused = (data) => {
  return http.post(`/appointments/refused`, data)
}

const getCSVTemplate = () => {
  return http.get(`/import/appointment-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/appointments`, data)
}

const emailExportExcel = (param) => {
  return http.get(`/export/appointments`, { params: param })
}

const AppointmentDataService = {
  getStatuses,
  getConditions,
  getAll,
  get,
  create,
  update,
  getTasks,
  remove,
  refused,
  getCSVTemplate,
  importCSV,
  emailExportExcel,
}
export default AppointmentDataService
