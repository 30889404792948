export const INITIAL_ITEM = {
  client_id: '',
  item_type_id: '',
  upc: '',
  sku: '',
  order_unit: '',
  supplier_code: '',
  kit_surcharge: 0,
  item_status_id: '1',
  quarantine_days: 0,
  pre_alert_days: 0,
  low_level_warning: 0,
  descriptions: [],
  department: '',
  sub_department: '',
  category: '',
  sub_category: '',
  season: '',
  item_linguistic_id: '',
  year: '',
  color: '',
  size: '',
  material: '',
  price: '',
  currency_id: '',
  lot_expiry_needed: 0,
  length_class_id: 1,
  weight_class_id: 1,
  manufacturer: [],
  measurements: [],
  attributes: [],
  kits: [],
  hazmats: {},
  supplier_id: '',
  supply_type_id: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  item_condition_id: 1,
  base_uom_type_id: 1,
  barcoded: 0,
  retentions_samples_needed: '',
  retentions_samples_quantity: 0,
  qa_release_needed: 0,
  populate_order: 0,
}

export const INITIAL_MANUFACTURER = {
  original_country_id: '',
  code: '',
  mid: '',
  phone: '',
  name: '',
  country_id: '',
  province_id: '',
  city: '',
  postal_code: '',
  address1: '',
  address2: '',
  description: '',
  international_ship: 0,
}

export const INITIAL_MEASUREMENT = {
  uom_type_id: '',
  quantity: '',
  length: 0,
  weight: 0,
  height: 0,
  width: 0,
  barcode: '',
  description: '',
}

export const INITIAL_KIT = {
  child_item_id: '',
  quantity: '',
  mix_lot: 0,
  lot_kit: 0,
  populate_order: 0,
}

export const INITIAL_HAZMAT = {
  un_number: '',
  class: '',
  quantity: '',
  packing_group: '',
  description: '',
}

export const TABLE_HEAD_PROPS = {
  align: 'start',
}

export const TABLE_PROPS = {
  hover: true,
  striped: true,
  bordered: true,
  responsive: true,
}

export const INITIAL_FILLED_DATA = {
  client: '',
  sku: '',
  description: '',
}

export const INITIAL_ITEM_SEARCH_PARAMS = {
  id: '',
  client_id: '',
  sku_upc: '',
  item_status_id: 1,
  item_type_id: '',
  ambient: '',
  cold_chain: '',
  restricted_access: '',
  controlled: '',
  hazmat: '',
  restricted_pharma: '',
  restricted_ecomm: '',
  restricted_precursor: '',
  sort_method: 'desc',
  order_by: 'id',
  synced: '',
  limit: 15,
  page: 1,
}

export const INITIAL_INVENTORY_AS_OF = {
  client_id: '',
  inventory_date: '',
  language_id: '',
}

export const INITIAL_SKU_USAGE = {
  client_id: '',
  date_from: '',
  date_to: '',
  language_id: '',
  show_0: 0,
}

export const CLIENTS = {
  ALL_CLIENTS: 0,
  GROUP_3DM: 1,
}
