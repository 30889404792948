import { SORT_OPTIONS } from 'src/constants/Constants'

export const INITIAL_RECEIVING_TRANSACTIONS_SEARCH_PARAMS = {
  client_id: '',
  sku_like: '',
  receiving_date: '',
  receiving_id: '',
  po_number_like: '',
  qty_received: '',
  lot: '',
  expiry_date: '',
  date_from: '',
  date_to: '',
  quantity_type_id: '',
  item_reason_id: '',
  sort_method: SORT_OPTIONS.DESC,
  order_by: 'created_at',
  limit: 15,
  page: 1,
}
