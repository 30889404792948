import { SELECT_MAXIMUM_LIMIT, TICKET_STATUS } from 'src/constants/Constants'

// Remove value Array check in SearchForm for ticket_type_id when enable packing  and remove filter in TodoListMobile data
export const INITIAL_JOBS_SEARCH_PARAMS = {
  id: '',
  ticket_type_id: '',
  warehouse_id: '',
  start_date: '',
  batch_id: '',
  client_id: '',
  picking_method_id: '',
  user_name: '',
  ticket_status_id: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_PICKING_DETAILS_SEARCH = {
  location_id: '',
  user_id: '',
  user_name: '',
  item_id: '',
  order_number: '',
}

export const INITIAL_JOB_SEARCH = {
  sort_method: 'desc',
  order_by: 'id',
  ticket_status_id: [TICKET_STATUS.OPEN, TICKET_STATUS.IN_PROGRESS],
  limit: SELECT_MAXIMUM_LIMIT,
  page: 1,
}

export const INITIAL_JOB_DETAILS = {
  warehouse_id: '',
  picking_method_id: '',
  user_id: '',
}
