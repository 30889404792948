import http from '../utils/http-common'

const getAll = (param, cancelToken) => {
  return http.get('/tickets', {
    params: param,
    cancelToken: cancelToken?.token,
  })
}

const get = (id) => {
  return http.get(`/tickets/${id}`)
}

const getJobsStatus = () => {
  return http.get(`/tickets-statuses`, {})
}

const getJobsType = () => {
  return http.get(`/tickets-types`, {})
}

const updateJob = (id, data) => {
  return http.put(`/tickets/${id}`, data)
}

const getReplenishment = (id) => {
  return http.get(`/replenishment/${id}`)
}

const getCSVTemplate = () => {
  return http.get(`/import/job-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/jobs`, data)
}

const getPickingDetails = (param) => {
  return http.get(`/order-item-pickings`, { params: param })
}

const getTransferJobDetails = (id) => {
  return http.get(`/tickets/inventory/transfer/details/${id}`)
}

const emailExportExcel = (param) => {
  return http.get(`/export/jobs`, { params: param })
}

const transferItemsToLocation = (id, data) => {
  return http.post(`tickets/inventory/transfer/${id}`, data)
}

const skipMoveSheetItem = (id, data) => {
  return http.post(`tickets/inventory/transfer/skip/${id}`, data)
}

const unSkipMoveSheetItem = (id, data) => {
  return http.post(`tickets/inventory/transfer/skip/remove/${id}`, data)
}

const completeMoveSheet = (id, data) => {
  return http.post(`tickets/inventory/transfer/complete/${id}`, data)
}

const JobsDataService = {
  getAll,
  get,
  getJobsStatus,
  getJobsType,
  updateJob,
  getReplenishment,
  getCSVTemplate,
  importCSV,
  getPickingDetails,
  emailExportExcel,
  getTransferJobDetails,
  transferItemsToLocation,
  skipMoveSheetItem,
  unSkipMoveSheetItem,
  completeMoveSheet,
}

export default JobsDataService
