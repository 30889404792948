import { SET_APPOINTMENTS_SEARCH_PARAMS } from 'src/actions/actionType'
import { INITIAL_APPOINTMENTS_SEARCH_PARAMS } from 'src/views/appointments/partials/Constants'

const initialState = {
  searchParams: INITIAL_APPOINTMENTS_SEARCH_PARAMS,
}

export default function AppointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_APPOINTMENTS_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      }
    default:
      return state
  }
}
