export const PACKAGE_TRANSFER = {
  order_package_id: '',
  internal_order: '',
  cage_name: '',
  door_name: '',
}

export const INITIAL_SHIPPING_SEARCH_PARAMS = {
  id: '',
  client_id: '',
  order_number: '',
  order_package_status_id: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}
