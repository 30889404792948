import { INITIAL_PURCHASE_ORDER_SEARCH_PARAMS } from 'src/views/purchase-order/partials/Constants'
import {
  PO_CONDITIONS_DATA,
  PO_STATUSES_DATA,
  SET_PURCHASE_ORDER_SEARCH_PARAMS,
} from '../actions/actionType'
const initialState = {
  conditions: [],
  statuses: [],
  searchParams: INITIAL_PURCHASE_ORDER_SEARCH_PARAMS,
}
export default function PurchaseOrderReducer(state = initialState, action) {
  switch (action.type) {
    case PO_CONDITIONS_DATA:
      return { ...state, conditions: action.payload }
    case PO_STATUSES_DATA:
      return { ...state, statuses: action.payload }
    case SET_PURCHASE_ORDER_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
