import { INITIAL_RECEIVING_TRANSACTIONS_SEARCH_PARAMS } from 'src/views/inventory/receiving-transactions/partials/Constants'
import { SET_RECEIVING_TRANSACTIONS_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  searchParams: INITIAL_RECEIVING_TRANSACTIONS_SEARCH_PARAMS,
}

export default function ReceivingTransactionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECEIVING_TRANSACTIONS_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
