import { COMMON_STATUS, LOCATION_CATEGORIES } from 'src/constants/Constants'

export const INITIAL_ZONE_SEARCH_PARAMS = {
  id: '',
  status: '',
  warehouse_id: '',
  zone_name: '',
  zone_category_id: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_ZONE = {
  status: 1,
  warehouse_id: '',
  zone_category_id: '',
  name: '',
  zone_items: [],
}

export const INITIAL_ZONE_ITEM = {
  status: COMMON_STATUS.ACTIVE,
  replenish_item_status_id: COMMON_STATUS.ACTIVE,
  location_id: '',
  item_id: '',
  min_quantity: 0,
  max_quantity: 0,
  allow_mixed_lots: false,
  max_equal_order: false,
}

export const ADDITIONAL_LOCATIONS = {
  location_category_id: [
    LOCATION_CATEGORIES.BIN,
    LOCATION_CATEGORIES.SHELF,
    LOCATION_CATEGORIES.SKID,
    LOCATION_CATEGORIES.PALETTE,
    LOCATION_CATEGORIES.STACKABLE,
    LOCATION_CATEGORIES.AREA,
    LOCATION_CATEGORIES.LEGACY_BIN,
    LOCATION_CATEGORIES.LEGACY_SHELF,
    LOCATION_CATEGORIES.LEGACY_RACKING,
    LOCATION_CATEGORIES.LEGACY_STACKABLE,
    LOCATION_CATEGORIES.PACKING_TABLE,
    LOCATION_CATEGORIES.TOTE,
  ],
}
