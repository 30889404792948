export const INITIAL_PROVINCES_SEARCH_PARAMS = {
  page: 1,
  name: '',
  id: '',
  code: '',
  country_id: '',
  sort_method: 'desc',
  order_by: 'id',
}

export const INITIAL_PROVINCE = {
  id: null,
  country_id: '',
  code: '',
  status: 0,
  sort_order: 100,
  descriptions: [],
}

export const INITIAL_DESCRIPTIONS = [
  {
    name: '',
    language_id: 1,
  },
  {
    name: '',
    language_id: 2,
  },
]
