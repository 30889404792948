import { INITIAL_COUNTRIES_SEARCH_PARAMS } from 'src/views/system/countries/partials/Constants'
import { COUNTRIES_DATA, SET_COUNTRIES_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  countries: [],
  searchParams: INITIAL_COUNTRIES_SEARCH_PARAMS,
}
export default function CountriesReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_DATA:
      return { ...state, countries: action.payload }
    case SET_COUNTRIES_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
