export const INITIAL_SUPPLIERS_SEARCH_PARAMS = {
  id: '',
  name: '',
  email: '',
  client_id: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_SUPPLIER = {
  name: '',
  status: '',
  email: '',
  first_name: '',
  client_id: '',
  last_name: '',
  phone: '',
  address1: '',
  address2: '',
  country_id: '',
  province_id: '',
  city: '',
  postal_code: '',
}
