export const LOGIN_BEGIN = 'LOGIN_BEGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const NO_USER = 'NO_USER'
export const USER_DATA = 'USER_DATA'
export const USER_NOT_SAVED_DATA = 'USER_NOT_SAVED_DATA'
export const LOGOUT_BEGIN = 'LOGOUT_BEGIN'
export const SETTING_DATA = 'SETTING_DATA'
export const LANGUAGE_DATA = 'LANGUAGE_DATA'
export const CHANGE_LANGUAGE_DATA = 'CHANGE_LANGUAGE_DATA'
export const COUNTRIES_DATA = 'COUNTRIES_DATA'
export const PROVINCES_DATA = 'PROVINCES_DATA'
export const CARRIERS_DATA = 'CARRIERS_DATA'
export const SERVICES_DATA = 'SERVICES_DATA'
export const ORDER_TYPES_DATA = 'ORDER_TYPES_DATA'
export const DEPARTMENTS_DATA = 'DEPARTMENTS_DATA'
export const SUPPLIERS_DATA = 'SUPPLIERS_DATA'
export const APPOINTMENT_TASKS_DATA = 'APPOINTMENT_TASKS_DATA'
export const APPOINTMENT_STATUS_DATA = 'APPOINTMENT_STATUS_DATA'
export const CURRENCY_DATA = 'CURRENCY_DATA'
export const MODIFICATION_TYPE_DATA = 'MODIFICATION_TYPE_DATA'
export const ITEM_REASON_DATA = 'ITEM_REASON_DATA'
export const WAREHOUSE_DATA = 'WAREHOUSE_DATA'
export const PO_CONDITIONS_DATA = 'PO_CONDITIONS_DATA'
export const PO_STATUSES_DATA = 'PO_STATUSES_DATA'
export const PO_ITEM_STATUSES_DATA = 'PO_ITEM_STATUSES_DATA'
export const SHIPPING_METHODS_DATA = 'SHIPPING_METHODS_DATA'
export const ORDER_STATUS_DATA = 'ORDER_STATUS_DATA'
export const ORDER_ITEM_STATUS_DATA = 'ORDER_ITEM_STATUS_DATA'
export const ORDER_PACKAGE_STATUS_DATA = 'ORDER_ITEM_PACKAGE_DATA'
export const RECEIVING_STATUS_DATA = 'RECEIVING_STATUS_DATA'
export const DELIVERY_STATUS_DATA = 'DELIVERY_STATUS_DATA'
export const SALUTATION_DATA = 'SALUTATION_DATA'
export const UOM_TYPE_DATA = 'UOM_TYPE_DATA'
export const LOCATION_CATEGORY_DATA = 'LOCATION_CATEGORY_DATA'
export const LENGTH_CLASS_DATA = 'LENGTH_CLASS_DATA'
export const WEIGHT_CLASS_DATA = 'WEIGHT_CLASS_DATA'
export const SUPPLY_TYPES_DATA = 'SUPPLY_TYPES_DATA'
export const RETURN_REASON_DATA = 'RETURN_REASON_DATA'
export const RETURN_STATUSES_DATA = 'RETURN_STATUSES_DATA'
export const RETURN_ACTIONS_DATA = 'RETURN_ACTIONS_DATA'
export const RETURN_ITEM_REASONS_DATA = 'RETURN_ITEM_REASONS_DATA'
export const RETURN_ITEM_CONDITIONS_DATA = 'RETURN_ITEM_CONDITIONS_DATA'
export const RETURN_ITEM_ACTIONS_DATA = 'RETURN_ITEM_ACTIONS_DATA'
export const RETURN_ITEM_STATUSES_DATA = 'RETURN_ITEM_STATUSES_DATA'
export const ITEM_TYPES_DATA = 'ITEM_TYPES_DATA'
export const ITEM_STATUS_DATA = 'ITEM_STATUS_DATA'
export const ITEM_LINGUISTICS_DATA = 'ITEM_LINGUISTICS_DATA'
export const JOBS_TYPE_DATA = 'JOBS_TYPE_DATA'
export const JOBS_STATUS_DATA = 'JOBS_STATUS_DATA'
export const BILLING_CATEGORIES_DATA = 'BILLING_CATEGORIES_DATA'
export const BILLING_LINE_CHARGES_DATA = 'BILLING_LINE_CHARGES_DATA'
export const BILLING_UOM_DATA = 'BILLING_UOM_DATA'
export const DOOR_TYPE_DATA = 'DOOR TYPE'
export const HOLIDAY_DATA = 'Holiday TYPE'
export const TAX_DATA = 'TAXES'
export const DELIVERY_TYPE_DATA = 'DELIVERY_TYPE_DATA'
export const RECEIVING_PO_STATUS_DATA = 'RECEIVING_PO_STATUS_DATA'
export const RECEIVING_PO_REASON_DATA = 'RECEIVING_PO_REASON_DATA'
export const RECEIVING_ITEM_STATUS_DATA = 'RECEIVING_ITEM_STATUS_DATA'
export const RECEIVING_ITEM_CONDITION_DATA = 'RECEIVING_ITEM_CONDITION_DATA'
export const RECEIVING_ITEM_REASON_DATA = 'RECEIVING_PO_REASON_DATA'
export const QUANTITY_TYPES_DATA = 'QUANTITY_TYPES_DATA'
export const ITEM_CONDITIONS_DATA = 'ITEM_CONDITIONS_DATA'
export const PICKING_METHODS_DATA = 'PICKING_METHODS_DATA'
export const PICKABLE_TYPES_DATA = 'PICKABLE_TYPES_DATA'
export const ERRORS_DATA = 'ERRORS_DATA'
export const ORDER_FIELD_LIST = 'ORDER_FIELD_LIST'
export const SET_ORDER_SEARCH_PARAMS = 'SET_ORDER_SEARCH_PARAMS'
export const SET_INVENTORY_SEARCH_PARAMS = 'SET_INVENTORY_SEARCH_PARAMS'
export const SET_JOBS_SEARCH_PARAMS = 'SET_JOBS_SEARCH_PARAMS'
export const SET_COUNTRIES_SEARCH_PARAMS = 'SET_COUNTRIES_SEARCH_PARAMS'
export const SET_PROVINCES_SEARCH_PARAMS = 'SET_PROVINCES_SEARCH_PARAMS'
export const SET_APPOINTMENTS_SEARCH_PARAMS = 'SET_APPOINTMENTS_SEARCH_PARAMS'
export const SET_TRANSACTIONS_SEARCH_PARAMS = 'SET_TRANSACTIONS_SEARCH_PARAMS'
export const CLIENTS_DATA = 'CLIENTS_DATA'
export const PICKING_SKIPS_DATA = 'PICKING_SKIPS_DATA'
export const PICKING_SKIP_REASONS_DATA = 'PICKING_SKIP_REASONS_DATA'
export const TRANSACTION_TYPES_DATA = 'TRANSACTION_TYPES_DATA'
export const INVOICE_STATUS_DATA = 'INVOICE_STATUS_DATA'
export const PROCESSING_TYPES_DATA = 'PROCESSING_TYPES_DATA'
export const ZONE_CATEGORIES_DATA = 'ZONE_CATEGORIES_DATA'
export const REPLENISH_STATUS_DATA = 'REPLENISH_STATUS_DATA'
export const SET_RETURN_SEARCH_PARAMS = 'SET_RETURN_SEARCH_PARAMS'
export const RETURN_ORDER_SEARCH_ADD = 'RETURN_ORDER_SEARCH_ADD'
export const SET_RECEIVING_TRANSACTIONS_SEARCH_PARAMS = 'SET_RECEIVING_TRANSACTIONS_SEARCH_PARAMS'
export const SET_SUPPLIERS_SEARCH_PARAMS = 'SET_SUPPLIERS_SEARCH_PARAMS'
export const SET_PURCHASE_ORDER_SEARCH_PARAMS = 'SET_PURCHASE_ORDER_SEARCH_PARAMS'
export const SET_ITEM_SEARCH_PARAMS = 'SET_ITEM_SEARCH_PARAMS'
export const SET_RECEIVING_SEARCH_PARAMS = 'SET_RECEIVING_SEARCH_PARAMS'
export const SET_ZONE_SEARCH_PARAMS = 'SET_ZONE_SEARCH_PARAMS'
export const SET_SHIPPING_SEARCH_PARAMS = 'SET_SHIPPING_SEARCH_PARAMS'
export const THEME = 'THEME'
