import { SKU, SKU_LIKE } from 'src/constants/Constants'

export const INITIAL_RECEIVING_SEARCH_PARAMS = {
  id: '',
  client_id: '',
  arrival_date: '',
  arrival_time: '',
  department_id: '',
  asn: '',
  carrier_id: '',
  purchase_order_number: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const INITIAL_FILTERS = {
  filter: SKU,
  filter_like: SKU_LIKE,
  item_type_id: 1,
}

export const RECEIVING_ITEM_NAME_STATUS = {
  1: 'IN PROGRESS',
  2: 'ON_HOLD',
  3: 'PENDING',
  4: 'VERIFICATION',
  5: 'FINALIZED',
  6: 'ERROR',
}

export const RECEIVING_PO = {
  EMPTY: 0,
  NOT_EMPTY: 1,
}

export const VIEW_TABS = {
  ITEMS: 0,
  ATTACHMENTS: 1,
}
