import { INITIAL_RECEIVING_SEARCH_PARAMS } from 'src/views/receiving/partials/Constants'
import { SET_RECEIVING_SEARCH_PARAMS } from '../actions/actionType'

const initialState = {
  receiving: [],
  searchParams: INITIAL_RECEIVING_SEARCH_PARAMS,
}
export default function ReceivingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECEIVING_SEARCH_PARAMS:
      return { ...state, searchParams: action.payload }
    default:
      return state
  }
}
