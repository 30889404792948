export const INIT_RETURN_ITEM_STATE = {
  item_id: null,
  item: {},
  location_id: null,
  location: {},
  lot: '',
  expiry_date: '',
  lot_expiry_needed: null,
  quantity: 0,
  uom_type_id: null,
  receiving_item_id: null,
  receiving_item: {},
  return_item_reason_id: null,
  return_item_action_id: null,
  return_item_condition_id: null,
  return_item_status_id: null,
  return_item_condition_other: '',
  order_item_id: null,
  order_item: {},
}

export const INITIAL_ORDER = {
  order_id: '',
  client_id: '',
  status: '',
  return_status_id: '',
  order_status_id: '',
  order_number: '',
  internal_order: '',
  order_date: '',
  company: '',
  first_name: '',
  last_name: '',
  address1: '',
  address2: '',
  province_id: '',
  city: '',
  postal_code: '',
  country_id: '',
  order_tracking_number: '',
  date_returned: '',
  date_processed: '',
  order_carrier_id: '',
  tracking_number: '',
  processed_by: '',
  rma_number: '',
  return_reason_id: '',
  return_details: '',
  notes: '',
  image_1: '',
  image_2: '',
}

export const INITIAL_ORDER_DATA = {
  order_id: '',
  client_id: '',
  status: '',
  internal_order: '',
  order_number: '',
  first_name: '',
  last_name: '',
  address1: '',
  address2: '',
  company: '',
  city: '',
  order_carrier_id: '',
  province_id: '',
  postal_code: '',
  country_id: '',
}

export const INITIAL_ITEMS = {
  sku: '',
  item_id: '',
  order_item_id: '',
  descriptions: [],
  lot: '',
  expiry_date: '',
  quantity_ordered: '',
  quantity: '',
  return_item_details: '',
  return_item_condition_id: '',
  return_item_reason_id: '',
  return_item_action_id: '',
  return_item_status_id: '',
  warehouse_id: '',
  location_id: '',
}

export const INITIAL_RETURN_ORDER_SEARCH_PARAMS = {
  warehouse_id: '',
  zone_id: '',
  client_id: '',
  internal_order: '',
  order_number: '',
}

export const INITIAL_RETURN_SEARCH_PARAMS = {
  client_id: '',
  start_date: '',
  end_date: '',
  return_state_id: '',
  return_status_id: '',
  internal_order: '',
  order_number: '',
  client_po: '',
  sort_method: 'desc',
  order_by: 'id',
  limit: 15,
  page: 1,
}

export const RETURN_ITEM_DETAILS_AUBAINERIE = [
  'too-big',
  'too-small',
  'not-what-i-thought',
  'arrived-too-late',
  'damaged',
  'wrong-item',
  'received-twice',
  'changed-mind',
  'other',
]

export const RETURN_ACTION = {
  DELETE: 'delete',
  REOPEN: 'reopen',
}
